import React, { useMemo } from 'react';
import LottieEx from 'lottie-react-web';

const LottieWrapper = ({
  isLoop = true,
  isAutoPlay = true,
  path,
  ...props
}) => {
  const options = useMemo(
    () => ({
      path,
      autoplay: isAutoPlay,
      loop: isLoop,
    }),
    [path, isAutoPlay, isLoop]
  );

  return <LottieEx resizeMode="cover" options={options} {...props} />;
};

export default LottieWrapper;
